import { Badge, Box, Group, Text, Title } from "@mantine/core";
import { useApi } from "../useApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  validateUserQuest,
  getUserQuests,
  invalidateUserQuest,
} from "../apiRoutes";
import { format } from "date-fns";
import QuestList from "../Components/QuestList";
import ContributionGraph from "../Components/ContributionGraph";
import ProfileButtons from "../Components/ProfileButtons";
import { UserQuest } from "../dto/user";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import FinishQuestModalWrapper from "../Components/FinishQuestModalWrapper";
import CustomQuestButton from "../Components/CustomQuestButton";

const Quests = () => {
  const { user } = useContext(UserContext);
  const [selectedQuest, setSelectedQuest] = useState<UserQuest | null>(null);
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: userQuests, isLoading: isLoadingQuests } = useQuery({
    queryKey: ["userQuests"],
    queryFn: () => getUserQuests(api),
  });

  const activeQuests = userQuests
    ? userQuests.filter((quest: UserQuest) => quest.endDate === null)
    : [];
  const totalQuestPoints =
    userQuests?.reduce((total, quest) => {
      return total + (quest.DailyQuestValidation?.length || 0);
    }, 0) || undefined;

  const validateQuestMutation = useMutation({
    mutationFn: (data: { userQuestId: number; date: string }) =>
      validateUserQuest(api, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
    },
  });

  const cancelQuestMutation = useMutation({
    mutationFn: (userQuestId: number) =>
      api.delete(`/user-quest/${userQuestId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
    },
  });

  const handleCancelQuest = (userQuestId: number) => {
    cancelQuestMutation.mutate(userQuestId);
  };

  const handleValidateQuest = (userQuestId: number, date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    validateQuestMutation.mutate({ userQuestId, date: dateString });
  };

  const handleFinishQuest = (userQuestId: number) => {
    const quest = activeQuests?.find((q: UserQuest) => q.id === userQuestId);
    setSelectedQuest(quest || null);
  };

  const invalidateQuestMutation = useMutation({
    mutationFn: (data: { userQuestId: number; date: string }) =>
      invalidateUserQuest(api, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
    },
  });

  const handleInvalidateQuest = (userQuestId: number, date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    invalidateQuestMutation.mutate({ userQuestId, date: dateString });
  };

  return (
    <Box p={16}>
      <Title>Quêtes</Title>

      <Group align="center" mt={16}>
        <Title order={3}>Petits pas</Title>
        <Badge variant="filled" color="theme">
          {totalQuestPoints} points
        </Badge>
        <ProfileButtons
          username={user?.pseudo ?? ""}
          isPublicProfile={user?.isPublicProfile ?? false}
        />
        <CustomQuestButton />
      </Group>
      <Text c="dimmed" size="xs">
        Choisis jusqu'à 3 actions quotidiennes pour te rapprocher des tes
        objectifs ou développer tes compétences
      </Text>

      {isLoadingQuests ? (
        <Text>Chargement des quêtes en cours...</Text>
      ) : userQuests && userQuests.length > 0 ? (
        <>
          <ContributionGraph userQuests={userQuests} />
          <QuestList
            userQuests={userQuests ?? []}
            onValidateQuest={handleValidateQuest}
            onInvalidateQuest={handleInvalidateQuest}
            onFinishQuest={handleFinishQuest}
            onCancelQuest={handleCancelQuest}
          />
        </>
      ) : (
        <Text mt={16}>Aucune quête en cours pour le moment.</Text>
      )}

      {selectedQuest && (
        <FinishQuestModalWrapper
          selectedQuest={selectedQuest}
          onClose={() => setSelectedQuest(null)}
        />
      )}
    </Box>
  );
};
export default Quests;
