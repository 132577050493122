import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSkills } from "../apiRoutes";
import { useApi } from "../useApi";
import { Skill, User } from "../dto/user";
import { UserContext } from "./UserContext";

interface SkillsContextType {
  skills: Skill[] | undefined;
  isLoading: boolean;
  error: Error | null;
  averageLevel: number;
  totalSkills: number;
}

const SkillsContext = createContext<SkillsContextType | undefined>(undefined);

const useVisibleSkills = (skills: Skill[] | undefined, user: User | null) => {
  return useMemo(() => {
    if (!skills || !user) return undefined;

    return skills.filter((skill) => {
      if (skill.area?.value === "couple") {
        return (
          user.relationshipStatus === "RELATIONSHIP" ||
          user.relationshipStatus === "MARRIED"
        );
      }

      if (skill.area?.value === "family") {
        return user.hasChildren === true;
      }

      return true;
    });
  }, [skills, user]);
};

export const SkillsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const api = useApi();
  const { isLoggedIn, user } = useContext(UserContext);
  const {
    data: allSkills,
    isLoading,
    error,
  } = useQuery<Skill[], Error>({
    queryKey: ["skills"],
    queryFn: () => getSkills(api),
    enabled: !!isLoggedIn,
  });

  const skills = useVisibleSkills(allSkills, user);

  const { averageLevel, totalSkills } = useMemo(() => {
    if (!skills) return { averageLevel: 0, totalSkills: 0 };

    const totalSkills = skills.length;
    const totalLevels = skills.reduce(
      (acc: number, skill: Skill) => acc + (skill.userLevel ?? 0),
      0
    );
    const averageLevel =
      totalSkills > 0 ? Number((totalLevels / totalSkills).toFixed(1)) : 0;

    return { averageLevel, totalSkills };
  }, [skills]);

  return (
    <SkillsContext.Provider
      value={{ skills, isLoading, error, averageLevel, totalSkills }}
    >
      {children}
    </SkillsContext.Provider>
  );
};

export const useSkills = () => {
  const context = useContext(SkillsContext);
  if (context === undefined) {
    throw new Error("useSkills must be used within a SkillsProvider");
  }
  return context;
};
