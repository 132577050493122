import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  ActionIcon,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import { Skill } from "../dto/user";
import { useNavigate } from "react-router-dom";

const SkillCard = ({ skill }: { skill: Skill }) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const getButtonStyle = () => {
    if (colorScheme === "dark" && !skill.activated) {
      return {
        backgroundColor: theme.colors.dark[6],
        color: theme.colors.dark[2],
        border: `1px solid ${theme.colors.dark[4]}`,
        "&:hover": {
          backgroundColor: theme.colors.dark[5],
        },
      };
    }
    return {};
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          src={
            skill.backgroundImgUrl && skill.backgroundImgUrl.length > 0
              ? skill.backgroundImgUrl
              : "https://images.pexels.com/photos/4439444/pexels-photo-4439444.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
          height={160}
          alt=""
        />
      </Card.Section>
      <Group justify="space-between" mt="md" mb="xs">
        <Text fw={500}>{skill.name}</Text>
        {!skill.activated && (
          <Badge color={skill.activated ? "green" : "red"}>
            {skill.activated ? "Disponible" : "À venir"}
          </Badge>
        )}
        {skill.userLevel !== 0 && (
          <ActionIcon
            variant="filled"
            size="lg"
            radius="xl"
            aria-label="Settings"
          >
            <Text size="sm" fw="bold">
              {skill.userLevel || 0}
            </Text>
          </ActionIcon>
        )}
      </Group>
      <Text size="sm" c="dimmed">
        {skill.description}
      </Text>

      <Button
        fullWidth
        mt="md"
        radius="md"
        disabled={!skill.activated}
        onClick={() => navigate(`/skills/${skill.id}`)}
        style={getButtonStyle()}
      >
        Voir la compétence
      </Button>
    </Card>
  );
};

export default SkillCard;
