import React, { useEffect, useRef, useState } from "react";
import {
  format,
  startOfYear,
  endOfYear,
  eachWeekOfInterval,
  isSameMonth,
  addDays,
  getMonth,
} from "date-fns";
import { UserQuest, DailyQuestValidation } from "../dto/user";
import {
  ScrollArea,
  Text,
  useMantineColorScheme,
  SegmentedControl,
} from "@mantine/core";
import "./ContributionGraph.css";
import { fr } from "date-fns/locale";
import PersistentTooltip from "./PersistentTooltip";

const ContributionGraph = ({
  userQuests,
}: {
  userQuests: UserQuest[] | undefined;
}) => {
  const [availableYears, setAvailableYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>("2025");
  const [contributionData, setContributionData] = useState<
    Record<string, number>
  >({});
  const [isMobile, setIsMobile] = useState(false);
  const graphRef = useRef<HTMLDivElement>(null);
  const { colorScheme } = useMantineColorScheme();

  // Détecter les années disponibles et initialiser l'année sélectionnée
  useEffect(() => {
    if (userQuests) {
      const years = new Set<string>();
      userQuests.forEach((userQuest: UserQuest) => {
        userQuest.DailyQuestValidation?.forEach(
          (validation: DailyQuestValidation) => {
            if (validation.date) {
              const year = new Date(validation.date).getFullYear().toString();
              years.add(year);
            }
          }
        );
      });
      const sortedYears = Array.from(years).sort();
      setAvailableYears(sortedYears);

      // Toujours sélectionner 2025 par défaut
      if (!selectedYear) {
        setSelectedYear("2025");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuests]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (userQuests && selectedYear) {
      const data: Record<string, number> = {};
      userQuests.forEach((userQuest: UserQuest) => {
        userQuest.DailyQuestValidation?.forEach(
          (validation: DailyQuestValidation) => {
            if (validation.date) {
              const validationDate = new Date(validation.date);
              const validationYear = validationDate.getFullYear().toString();
              if (validationYear === selectedYear) {
                const dateString = format(validationDate, "yyyy-MM-dd");
                data[dateString] = (data[dateString] || 0) + 1;
              }
            }
          }
        );
      });
      setContributionData(data);
    }
  }, [userQuests, selectedYear]);

  const getContributionLevel = (count: number): number => {
    if (count === 0) return 0;
    if (count === 1) return 1;
    if (count === 2) return 2;
    return 3;
  };

  const getContributionColor = (level: number) => {
    const darkModeColors = ["#1e1e2e", "#0e4429", "#006d32", "#26a641"];
    const lightModeColors = ["#ebedf0", "#9be9a8", "#40c463", "#30a14e"];
    const colors = colorScheme === "dark" ? darkModeColors : lightModeColors;
    return colors[level];
  };

  useEffect(() => {
    if (isMobile && graphRef.current) {
      const currentMonth = new Date().getMonth();
      const monthElement = graphRef.current.querySelector(
        `.month:nth-child(${currentMonth + 1})`
      );
      if (monthElement) {
        monthElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [isMobile]);

  const renderYearSelector = () => {
    if (availableYears.length === 1 && availableYears[0] === "2025")
      return null;
    const yearsToShow = Array.from(
      new Set([...availableYears, "2024", "2025"])
    ).sort();

    return (
      <SegmentedControl
        value={selectedYear}
        onChange={setSelectedYear}
        data={yearsToShow.map((year) => ({
          label: year,
          value: year,
        }))}
        mt="sm"
        mb={-10}
      />
    );
  };

  const renderGraph = () => {
    if (!selectedYear) return null;

    const startDate = startOfYear(new Date(parseInt(selectedYear, 10), 0, 1));
    const endDate = endOfYear(new Date(parseInt(selectedYear, 10), 0, 1));
    const weeks = eachWeekOfInterval(
      { start: startDate, end: endDate },
      { weekStartsOn: 1 }
    );

    const months = Array.from(new Set(weeks.map((week) => getMonth(week))));

    return (
      <div ref={graphRef}>
        <div className="months">
          {months.map((month) => (
            <Text key={month} className="month">
              {format(new Date(2023, month, 1), "MMM", { locale: fr })}
            </Text>
          ))}
        </div>
        <div className="graph-body">
          <div className="weekdays-container">
            <div
              className={
                colorScheme === "dark" ? "dark-square" : "white-square"
              }
            />
            <div
              className="weekdays"
              style={{
                backgroundColor: colorScheme === "dark" ? "#100022" : "white",
              }}
            >
              {["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"].map((day) => (
                <Text key={day} className="weekday">
                  {day}
                </Text>
              ))}
            </div>
          </div>
          <div className="weeks">
            {weeks.map((week, weekIndex) => {
              const days = Array.from({ length: 7 }, (_, i) =>
                addDays(week, i)
              );

              return (
                <div key={weekIndex} className="week">
                  {days.map((day) => {
                    const dateString = format(day, "yyyy-MM-dd");
                    const contributionCount = contributionData[dateString] || 0;
                    const tooltipContent = `${format(day, "EEEE d MMMM yyyy", {
                      locale: fr,
                    })}: ${contributionCount} ${
                      contributionCount !== 1 ? "petits pas" : "petit pas"
                    }`;

                    const DayComponent = (
                      <div
                        className={`day ${
                          isSameMonth(day, week) ? "" : "other-month"
                        }`}
                        style={{
                          backgroundColor: getContributionColor(
                            getContributionLevel(contributionCount)
                          ),
                        }}
                      />
                    );

                    return (
                      <PersistentTooltip
                        key={dateString}
                        label={tooltipContent}
                        isMobile={isMobile}
                      >
                        {DayComponent}
                      </PersistentTooltip>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  if (!selectedYear) return null;

  return (
    <>
      <>{renderYearSelector()}</>
      <ScrollArea mt="sm" pb="sm">
        <div className="contribution-graph">{renderGraph()}</div>
      </ScrollArea>
    </>
  );
};

export default ContributionGraph;
