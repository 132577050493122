import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";

interface QuizQuestion {
  id: number;
  questionFr: string;
  explanationFr: string;
  answers: string;
}

interface ParsedAnswers {
  options: string[];
  correctAnswer: string;
}

interface ShuffledOptionsMap {
  [key: number]: Array<{ option: string; value: string }>;
}

const parseAnswers = (answersJson: string): ParsedAnswers | null => {
  try {
    return JSON.parse(answersJson);
  } catch (e) {
    console.error("Error parsing answers JSON:", e);
    return null;
  }
};

export const useQuizQuestions = (gameId: number, api: any) => {
  const [lock, setLock] = useState(false);
  const [shuffledOptionsMap, setShuffledOptionsMap] =
    useState<ShuffledOptionsMap>({});

  const {
    data: questions,
    isLoading,
    error,
  } = useQuery<QuizQuestion[]>({
    queryKey: ["quizQuestions", gameId],
    queryFn: () =>
      api.get(`/mini-games/${gameId}/questions`).then((res: any) => res.data),
    enabled: !lock, // La requête ne s'exécutera pas si lock est true
  });

  useEffect(() => {
    if (error) {
      notifications.show({
        title: "Erreur",
        message: "Impossible de charger les questions du quiz.",
        color: "red",
      });
    }
  }, [error]);

  useEffect(() => {
    if (questions?.length && Object.keys(shuffledOptionsMap).length === 0) {
      setLock(true);
      const newShuffledOptionsMap: ShuffledOptionsMap = {};
      questions.forEach((question) => {
        const parsedAnswers = parseAnswers(question.answers);
        if (parsedAnswers?.options?.length) {
          const optionsWithValues = parsedAnswers.options.map(
            (option, index) => ({
              option,
              value: String.fromCharCode(65 + index),
            })
          );
          newShuffledOptionsMap[question.id] = [...optionsWithValues].sort(
            () => Math.random() - 0.5
          );
        }
      });
      setShuffledOptionsMap(newShuffledOptionsMap);
    }
  }, [questions, shuffledOptionsMap]);

  return {
    questions,
    isLoading,
    error,
    shuffledOptionsMap,
    parseAnswers,
  };
};

export type { QuizQuestion, ParsedAnswers, ShuffledOptionsMap };
