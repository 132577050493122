import { AxiosInstance } from "axios";
import { useApi } from "./useApi";
import { MiniGame, Quest, QuestCategory, Skill, UserQuest } from "./dto/user";

export const getMe = async (api: AxiosInstance) => {
  const response = await api.get("/user/me");
  return response.data;
};

export const getUserData = async (api: AxiosInstance) => api.get("/user");

export const postUserData = async (api: AxiosInstance, formValues: any) =>
  api.post("/user", { formValues });

export const login = async (
  api: AxiosInstance,
  email: string,
  password: string
) => await api.post("/auth/login", { email, password });

export const register = async (
  api: AxiosInstance,
  email: string,
  password: string,
  pseudo: string,
  isPublicProfile: boolean
) =>
  await api.post("/auth/register", {
    email,
    password,
    pseudo,
    isPublicProfile,
  });

export const getSkills = async (api: ReturnType<typeof useApi>) => {
  const response = await api.get<Skill[]>("/skill/skills");
  return response.data;
};

export const createEditSkill = async (
  api: AxiosInstance,
  skill: Omit<Skill, "id"> | Skill
) => {
  if ("id" in skill) {
    const response = await api.put(`/skill/${skill.id}`, skill);
    return response.data;
  } else {
    const response = await api.post("/skill", skill);
    return response.data;
  }
};

export const getSkill = async (api: AxiosInstance, skillId: number) => {
  const response = await api.get(`/skill/${skillId}`);
  return response.data;
};

export const updateUserSkill = async (
  api: ReturnType<typeof useApi>,
  skillId: number,
  level: number
) => {
  const response = await api.put(`/skill/${skillId}/level`, { level });
  return response.data;
};

export const getQuests = async (api: AxiosInstance): Promise<Quest[]> => {
  const response = await api.get("/quest/quests");
  return response.data;
};

export const createEditQuest = async (
  api: AxiosInstance,
  quest: Omit<Quest, "id"> | Quest
): Promise<Quest> => {
  if ("id" in quest) {
    const response = await api.put(`/quest/${quest.id}`, quest);
    return response.data;
  } else {
    const response = await api.post("/quest", quest);
    return response.data;
  }
};

export const getQuestCategories = async (
  api: AxiosInstance
): Promise<QuestCategory[]> => {
  const response = await api.get("/quest/categories");
  return response.data;
};

export const getQuestsForSkill = (api: AxiosInstance, skillId: number) =>
  api.get(`/quest/skill/${skillId}`).then((response) => response.data);

export const startQuest = (
  api: AxiosInstance,
  questId: number,
  startDate: string,
  customData?: {
    customTitle?: string;
    customDescription?: string;
    customPrecision?: string;
  }
) =>
  api
    .post("/user-quest", {
      questId,
      startDate,
      customTitle: customData?.customTitle,
      customDescription: customData?.customDescription,
      customPrecision: customData?.customPrecision,
    })
    .then((response) => response.data);

export const getUserActiveQuests = (api: AxiosInstance) =>
  api.get("/user-quest/active").then((response) => response.data);

export const validateUserQuest = async (
  api: AxiosInstance,
  data: { userQuestId: number; date: string }
) => {
  const response = await api.post("/user-quest/validate", data);
  return response.data;
};

export const finishUserQuest = (
  api: AxiosInstance,
  userQuestId: number,
  endDate: Date
) =>
  api
    .put(`/user-quest/${userQuestId}/finish`, {
      endDate: endDate.toISOString(),
    })
    .then((res) => res.data);

export const getUserQuests = (api: AxiosInstance): Promise<UserQuest[]> =>
  api.get("/quest/user-quests").then((res) => res.data);

export const getUserByPseudo = async (api: AxiosInstance, pseudo: string) => {
  const response = await api.get(`/user/by-pseudo/${pseudo}`);
  return response.data;
};

export const getUserQuestsByPseudo = async (
  api: AxiosInstance,
  pseudo: string
) => {
  const response = await api.get(`/quest/by-pseudo/${pseudo}`);
  return response.data;
};

export const invalidateUserQuest = (
  api: AxiosInstance,
  data: { userQuestId: number; date: string }
) => api.delete(`/user-quest/${data.userQuestId}/validation/${data.date}`);

export const addSkillEvaluation = async (
  api: AxiosInstance,
  evaluation: { skillId: number; level: number; date: Date }
) => {
  const localDate = new Date(evaluation.date);
  const response = await api.post(`/skill/${evaluation.skillId}/evaluation`, {
    level: evaluation.level,
    year: localDate.getFullYear(),
    month: localDate.getMonth() + 1,
    day: localDate.getDate(),
  });
  return response.data;
};

export const getUserSkillEvaluations = async (
  api: AxiosInstance,
  skillId: number
) => {
  const response = await api.get(`/skill/${skillId}/evaluations`);
  return response.data;
};

export const loginWithGoogle = (
  api: AxiosInstance,
  email: string,
  name: string,
  picture: string
) => {
  return api.post("/auth/google", { email, name, picture });
};

export const forgotPassword = async (api: any, email: string) => {
  return api.post("/auth/forgot-password", { email });
};

export const getMiniGames = (api: AxiosInstance) =>
  api.get("/mini-games").then((response) => response.data);

export const createEditMiniGame = async (
  api: AxiosInstance,
  miniGame: Omit<MiniGame, "id"> | MiniGame
) => {
  if ("id" in miniGame) {
    return api.put(`/mini-games/${miniGame.id}`, miniGame);
  } else {
    return api.post("/mini-games", miniGame);
  }
};

export const getMiniGamesForSkill = async (
  api: AxiosInstance,
  skillId: number
) => {
  const response = await api.get(`/mini-games/skill/${skillId}`);
  return response.data;
};

export const getQuestById = async (api: any, id: number) => {
  const response = await api.get(`/quest/${id}`);
  return response.data;
};
