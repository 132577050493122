import React, { useMemo } from "react";
import { BarChart } from "@mantine/charts";
import { Box, useMantineColorScheme } from "@mantine/core";
import { Skill } from "../dto/user";
import { useViewportSize } from "@mantine/hooks";

const AreasBarChart = ({ skills }: { skills: Skill[] | undefined }) => {
  const { width } = useViewportSize();
  const { colorScheme } = useMantineColorScheme();

  const chartData = useMemo(() => {
    if (!skills) return [];
    return skills.map((skill) => ({
      name: skill.name?.slice(0, width > 1140 ? 4 : width < 768 ? 2 : 3) + ".",
      fullName: skill.name || "Unnamed Skill",
      Niveau: skill.userLevel || 0,
    }));
  }, [skills, width]);

  if (!skills || skills.length === 0) return null;

  return (
    <Box mb={10}>
      <BarChart
        h={300}
        data={chartData}
        dataKey="name"
        series={[{ name: "Niveau", color: "violet.6" }]}
        orientation="horizontal"
        valueFormatter={(value) => value.toFixed(1)}
        tooltipAnimationDuration={200}
        tickLine="y"
        tooltipProps={{
          content: ({ payload }) => {
            if (payload && payload[0]) {
              return (
                <div
                  style={{
                    padding: "8px",
                    backgroundColor:
                      colorScheme === "dark" ? "#100022" : "white",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <div>{payload[0].payload.fullName}</div>
                  <div>Niveau: {payload[0].value?.toFixed(1)}</div>
                </div>
              );
            }
            return null;
          },
        }}
      />
    </Box>
  );
};

export default AreasBarChart;
