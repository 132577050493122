import axios from "axios";
import { useContext, useMemo } from "react";
import { UserContext } from "./context/UserContext";

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.uplyapp.com"
    : "http://localhost:8080";

const api = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
});

const publicRoutes = [
  { pattern: /\/auth\/login$/, method: "POST" },
  { pattern: /\/auth\/register$/, method: "POST" },
];

export function isPublicRoute(url: string, method: string) {
  return publicRoutes.some(
    (route) => route.pattern.test(url) && method === route.method
  );
}

// Variable pour suivre l'installation des intercepteurs
let interceptorsInstalled = false;

export function useApi() {
  const { setUser, user } = useContext(UserContext);

  // Installation unique des intercepteurs
  useMemo(() => {
    if (!interceptorsInstalled) {
      // Nettoyer les intercepteurs existants
      api.interceptors.request.clear();
      api.interceptors.response.clear();

      // Ajouter l'intercepteur de requête
      api.interceptors.request.use((config) => {
        if (!isPublicRoute(config.url ?? "", config.method ?? "")) {
          const user = JSON.parse(localStorage.getItem("user") || "{}");
          if (user && user.accessToken) {
            config.headers["Authorization"] = `Bearer ${user.accessToken}`;
          }
        }
        return config;
      });

      // Ajouter l'intercepteur de réponse
      api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (!isPublicRoute(error.config.url, error.config.method)) {
            if (error.response?.status === 401 && user) {
              localStorage.removeItem("user");
              setUser(null);
              window.location.reload();
            }
          }
          return Promise.reject(error);
        }
      );

      interceptorsInstalled = true;
    }
  }, [setUser, user]);

  return api;
}

export function resetApiInstance() {
  interceptorsInstalled = false;
  api.interceptors.request.clear();
  api.interceptors.response.clear();
}
