import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import {
  Box,
  Title,
  Text,
  LoadingOverlay,
  BackgroundImage,
  Breadcrumbs,
  Anchor,
  Group,
  TypographyStylesProvider,
  Table,
  Slider,
  Button,
  Flex,
  ScrollArea,
} from "@mantine/core";
import {
  addSkillEvaluation,
  getMiniGamesForSkill,
  getSkill,
  getUserSkillEvaluations,
  updateUserSkill,
} from "../apiRoutes";
import { areasOfLife } from "../utils/areasOfLife";
import { MiniGame, Quest, SkillEvaluation } from "../dto/user";
import MiniGameCard from "../Components/MiniGameCard";
import StartQuestModal from "../Components/StartQuestModal";
import { notifications } from "@mantine/notifications";
import { format } from "date-fns";
import ChartEvolution from "../Components/ChartEvolution";
import FeedbackButton from "../Components/FeedbackButton";
import MiniGameResultsAccordion from "../Components/MiniGameResultsAccordion";
import { UserContext } from "../context/UserContext";
import QuestSection from "../Components/QuestSection";

interface RatingTableItem {
  niveau: number;
  description: string;
}

const Skill = () => {
  const { skillId } = useParams();
  const { user } = useContext(UserContext);
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [skillLevel, setSkillLevel] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState<Quest | null>(null);

  const {
    data: skill,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["skill", skillId],
    queryFn: () => getSkill(api, +(skillId ? skillId : 0)),
  });

  const {
    data: userSkillEvaluations,
    isLoading: evaluationsLoading,
    error: evaluationsError,
  } = useQuery({
    queryKey: ["userSkillEvaluations", skillId],
    queryFn: () => getUserSkillEvaluations(api, +(skillId ? skillId : 0)),
  });

  const {
    data: miniGames,
    isLoading: miniGamesLoading,
    error: miniGamesError,
  } = useQuery({
    queryKey: ["miniGames", skillId],
    queryFn: () => getMiniGamesForSkill(api, +(skillId ? skillId : 0)),
    enabled: !!skillId,
  });

  useEffect(() => {
    if (userSkillEvaluations && userSkillEvaluations.length > 0) {
      const latestEvaluation = userSkillEvaluations.reduce(
        (latest: SkillEvaluation, current: SkillEvaluation) => {
          if (latest.id === undefined || current.id === undefined) {
            return current;
          }
          return current.id > latest.id ? current : latest;
        }
      );
      setSkillLevel(latestEvaluation.level ?? 0);
    } else {
      setSkillLevel(0);
    }
  }, [userSkillEvaluations]);

  const updateSkillMutation = useMutation({
    mutationFn: (level: number) =>
      updateUserSkill(api, +(skillId ? skillId : 0), level),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["skill", skillId] });
      queryClient.invalidateQueries({ queryKey: ["skills"] });
      setIsSuccess(true);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const addEvaluationMutation = useMutation({
    mutationFn: (evaluation: { skillId: number; level: number; date: Date }) =>
      addSkillEvaluation(api, evaluation),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userSkillEvaluations", skillId],
      });
      queryClient.invalidateQueries({ queryKey: ["skills"] });
      setIsSuccess(true);
    },
  });

  const handleSave = () => {
    if (skillId) {
      const today = new Date();
      addEvaluationMutation.mutate(
        {
          skillId: +skillId,
          level: skillLevel,
          date: today,
        },
        {
          onSuccess: () => {
            notifications.show({
              title: "Évaluation enregistrée",
              message: "Votre évaluation a été enregistrée avec succès.",
              color: "green",
            });
          },
          onError: (error) => {
            notifications.show({
              title: "Erreur",
              message:
                "Une erreur est survenue lors de l'enregistrement de l'évaluation.",
              color: "red",
            });
            console.error(
              "Erreur lors de l'enregistrement de l'évaluation:",
              error
            );
          },
        }
      );
    }
  };

  const chartData = userSkillEvaluations
    ?.sort((a: SkillEvaluation, b: SkillEvaluation) => {
      const dateA = a.date ? new Date(a.date).getTime() : 0;
      const dateB = b.date ? new Date(b.date).getTime() : 0;
      return dateA - dateB;
    })
    .map((evaluation: SkillEvaluation) => ({
      date: evaluation.date
        ? format(new Date(evaluation.date), "dd/MM/yyyy")
        : "N/A",
      Niveau: evaluation.level ?? 0,
    }));

  if (isLoading) return <LoadingOverlay visible={true} />;
  if (error) return <Text c="red">Error: {error.message}</Text>;

  const area = areasOfLife.find((a) => a.value === skill?.area?.value);

  const breadcrumbItems = [
    {
      title: area ? `${area.emoji} ${area.title}` : "Areas",
      href: `/areas/${area ? area.value : ""}`,
    },
    {
      title: "",
      href: "#",
    },
  ].map((item, index) => (
    <Anchor key={index} onClick={() => navigate(item.href)}>
      {item.title}
    </Anchor>
  ));

  let ratingTable: RatingTableItem[] = [];
  try {
    if (skill?.ratingTableJSON) {
      ratingTable = JSON.parse(skill.ratingTableJSON) as RatingTableItem[];
    }
  } catch (e) {
    console.error("Failed to parse ratingTableJSON:", e);
  }

  const handlePlayMiniGame = (miniGameId: number) => {
    // Implémentez la logique pour démarrer un mini-jeu
    // Cela pourrait impliquer la navigation vers une nouvelle page ou l'ouverture d'une modal
    console.log(`Démarrage du mini-jeu avec l'id : ${miniGameId}`);
    navigate(`/mini-game/${miniGameId}`);
  };

  return (
    <>
      <StartQuestModal
        quest={selectedQuest}
        skillId={skillId}
        onClose={() => setSelectedQuest(null)}
      />
      <BackgroundImage src={skill?.backgroundImgUrl ?? ""} mih={200} />
      <Box p={16}>
        <Group gap="0">
          <Breadcrumbs mb={6}>{breadcrumbItems}</Breadcrumbs>
          <Title mb={16}>{skill?.name}</Title>
        </Group>
        <Box mb={16}>
          <FeedbackButton pageUrl={window.location.href} />
        </Box>
        <Text>
          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: skill?.content ?? "" }} />
          </TypographyStylesProvider>
        </Text>

        <Box mt={30}>
          <Title order={3} mb={10}>
            Mini-Jeux
          </Title>
          {miniGamesLoading ? (
            <Text>Loading mini-games...</Text>
          ) : miniGamesError ? (
            <Text c="red">
              Error loading mini-games: {miniGamesError.message}
            </Text>
          ) : miniGames && miniGames.length > 0 ? (
            <ScrollArea>
              <Flex gap="md" style={{ overflowX: "auto", padding: "10px 0" }}>
                {miniGames.map((miniGame: MiniGame) => (
                  <MiniGameCard
                    key={miniGame.id}
                    miniGame={miniGame}
                    onPlay={handlePlayMiniGame}
                    isHorizontalScroll
                    isAdmin={user?.role === "admin"}
                  />
                ))}
              </Flex>
            </ScrollArea>
          ) : (
            <Text>Pas de mini-jeux disponible pour cette compétence.</Text>
          )}
        </Box>

        <QuestSection
          skillId={skillId}
          onQuestSelect={(quest) => setSelectedQuest(quest)}
        />

        <Box mt={30}>
          <MiniGameResultsAccordion skillId={+(skillId ?? 0)} />
        </Box>

        {ratingTable.length > 0 && (
          <Box mt={30}>
            <Title order={3} mb={10}>
              Table d'autoévaluation
            </Title>
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Niveau</Table.Th>
                  <Table.Th>Description</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {ratingTable.map((item: RatingTableItem, index: number) => (
                  <Table.Tr key={index}>
                    <Table.Td>{item.niveau}</Table.Td>
                    <Table.Td>{item.description}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Box>
        )}

        <Box mt={30}>
          <Title order={3} mb={10}>
            Votre niveau : {skillLevel}
          </Title>
          <Slider
            value={skillLevel}
            onChange={setSkillLevel}
            min={0}
            max={10}
            step={0.1}
            marks={[
              { value: 0, label: "0" },
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            label={(value) => value.toString()}
            mb="md"
            // disabled={true} // untill I find a better evaluation method
          />
          <Button
            onClick={handleSave}
            mt={16}
            loading={updateSkillMutation.isPending}
            color={isSuccess ? "green" : "theme"}
            disabled={true} // untill I find a better evaluation method
          >
            Sauvegarder
          </Button>
        </Box>

        {!evaluationsLoading &&
        !evaluationsError &&
        userSkillEvaluations.length ? (
          <Box mt={30}>
            <Title order={3} mb={10}>
              Évolution de votre niveau
            </Title>
            <ChartEvolution chartData={chartData} dataKey="Niveau" />
            <Table mt={20}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Niveau</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {userSkillEvaluations
                  .sort((a: SkillEvaluation, b: SkillEvaluation) => {
                    const dateA = a.date ? new Date(a.date).getTime() : 0;
                    const dateB = b.date ? new Date(b.date).getTime() : 0;
                    return dateB - dateA;
                  })
                  .map((evaluation: SkillEvaluation) => (
                    <Table.Tr key={evaluation.id}>
                      <Table.Td>
                        {evaluation.date
                          ? format(new Date(evaluation.date), "dd/MM/yyyy")
                          : "N/A"}
                      </Table.Td>
                      <Table.Td>{evaluation.level?.toFixed(1)}</Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Skill;
