import React from "react";
import { Box, Title, Text, ScrollArea, Flex } from "@mantine/core";
import { Quest } from "../dto/user";
import QuestCard from "./QuestCard";
import { useQuests } from "../hooks/useQuests";

interface QuestSectionProps {
  skillId: string | undefined;
  onQuestSelect: (quest: Quest) => void;
}

const QuestSection: React.FC<QuestSectionProps> = ({
  skillId,
  onQuestSelect,
}) => {
  const { quests, questsLoading, questsError } = useQuests(skillId);

  const handleStartQuest = (questId: number) => {
    const quest = quests?.find((q: Quest) => q.id === questId);
    if (quest) {
      onQuestSelect(quest);
    }
  };

  return (
    <Box mt={30}>
      <Title order={3} mb={10}>
        Quêtes
      </Title>
      {questsLoading ? (
        <Text>Chargement des quêtes...</Text>
      ) : questsError ? (
        <Text c="red">
          Erreur lors du chargement des quêtes: {questsError.message}
        </Text>
      ) : quests && quests.length > 0 ? (
        <ScrollArea>
          <Flex gap="md" style={{ overflowX: "auto", padding: "10px 0" }}>
            {quests.map((quest: Quest) => (
              <QuestCard
                key={quest.id}
                quest={quest}
                onStart={handleStartQuest}
              />
            ))}
          </Flex>
        </ScrollArea>
      ) : (
        <Text>Aucune quête disponible pour cette compétence.</Text>
      )}
    </Box>
  );
};

export default QuestSection;
