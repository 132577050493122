import React, { useState } from "react";
import {
  Modal,
  Button,
  Text,
  SegmentedControl,
  Group,
  TextInput,
  Textarea,
} from "@mantine/core";
import { Quest } from "../dto/user";
import { useQuests } from "../hooks/useQuests";
import { useQueryClient } from "@tanstack/react-query";

interface StartQuestModalProps {
  quest: Quest | null;
  skillId: string | undefined;
  onClose: () => void;
}

const StartQuestModal: React.FC<StartQuestModalProps> = ({
  quest,
  skillId,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { handleStartQuest } = useQuests(skillId);
  const [startDate, setStartDate] = useState<"today" | "tomorrow">("today");
  const [customTitle, setCustomTitle] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const [customPrecision, setCustomPrecision] = useState("");
  const [errors, setErrors] = useState({
    customTitle: "",
    customDescription: "",
  });

  if (!quest) return null;

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDateToString = (date: Date): string => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };

  const validate = () => {
    const newErrors = {
      customTitle: "",
      customDescription: "",
    };
    let isValid = true;

    if (quest.id === 4) {
      if (customTitle.trim() === "") {
        newErrors.customTitle = "Le titre personnalisé est requis";
        isValid = false;
      }
      if (customDescription.trim() === "") {
        newErrors.customDescription =
          "La description personnalisée est requise";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleStart = async () => {
    if (quest.id === undefined) {
      console.error("Quest ID is undefined");
      return;
    }

    if (!validate()) {
      return;
    }

    const selectedDate = startDate === "today" ? today : tomorrow;
    const dateString = formatDateToString(selectedDate);

    const success = await handleStartQuest(quest.id, dateString, {
      customTitle: customTitle || undefined,
      customDescription: customDescription || undefined,
      customPrecision: customPrecision || undefined,
    });

    if (success) {
      queryClient.invalidateQueries({ queryKey: ["userQuests"] });
      onClose();
    }
  };

  const handleChange = (value: string) => {
    if (value === "today" || value === "tomorrow") {
      setStartDate(value);
    }
  };

  return (
    <Modal opened={true} onClose={onClose} title="Démarrer une quête" size="md">
      <Text mb="md" fw={500}>
        {quest.title}
      </Text>
      {(quest.category?.value === "custom" || quest.id === 4) && (
        <>
          <TextInput
            required={quest.id === 4}
            label="Titre personnalisé"
            value={customTitle}
            onChange={(e) => {
              setCustomTitle(e.target.value);
              if (errors.customTitle) {
                setErrors((prev) => ({ ...prev, customTitle: "" }));
              }
            }}
            placeholder="Entrez un titre personnalisé"
            mb="md"
            error={errors.customTitle}
          />
          <Textarea
            required={quest.id === 4}
            label="Description personnalisée"
            value={customDescription}
            onChange={(e) => {
              setCustomDescription(e.target.value);
              if (errors.customDescription) {
                setErrors((prev) => ({ ...prev, customDescription: "" }));
              }
            }}
            placeholder="Entrez une description personnalisée"
            minRows={3}
            mb="md"
            error={errors.customDescription}
          />
        </>
      )}
      {quest.category?.value !== "custom" && quest.id !== 4 && (
        <Textarea
          label="Précision personnalisée (optionnelle)"
          value={customPrecision}
          onChange={(e) => setCustomPrecision(e.target.value)}
          mb="md"
        />
      )}

      <Text size="sm" mb="xs">
        Date de début:
      </Text>
      <Group mb="xl">
        <SegmentedControl
          fullWidth
          value={startDate}
          onChange={handleChange}
          data={[
            { label: "Aujourd'hui", value: "today" },
            { label: "Demain", value: "tomorrow" },
          ]}
        />
      </Group>
      <Button onClick={handleStart} fullWidth>
        Démarrer la quête
      </Button>
    </Modal>
  );
};

export default StartQuestModal;
