import {
  Box,
  Button,
  Center,
  Image,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import React from "react";
import { Features } from "../Components/Features";
import { areasOfLife } from "../utils/areasOfLife";

const AreasOfLife = ({ width }: { width: number }) => {
  const items = areasOfLife.map((area) => (
    <div key={area.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: "theme", to: "cyan" }}
      >
        {/* <area.icon
          style={{ width: rem(26), height: rem(26) }}
          stroke={1.5}
        /> */}
        {area.emoji}
      </ThemeIcon>
      <Text fz="lg" mt="sm" fw={500}>
        {area.title}
      </Text>
      <Text c="dimmed" fz="sm">
        {area.description}
      </Text>
    </div>
  ));

  return (
    <div
      style={{
        padding:
          width > 800
            ? "calc(var(--mantine-spacing-xl) * 2) var(--mantine-spacing-xl)"
            : 0,
        marginTop: width > 800 ? undefined : 60,
      }}
    >
      <SimpleGrid cols={{ base: 2, md: 4 }} spacing={30}>
        {items}
      </SimpleGrid>
    </div>
  );
};

const StartNowButton = () => (
  <Button
    variant="gradient"
    gradient={{ deg: 133, from: "theme", to: "cyan" }}
    size="lg"
    radius="md"
    mt="xl"
    onClick={() => {
      const deleteEvent = new CustomEvent("register");
      window.dispatchEvent(deleteEvent);
    }}
  >
    Démarre maintenant
  </Button>
);

const Landing = () => {
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  return (
    <Box p={16}>
      <Center>
        <Title
          mt={width > 800 ? 60 : 0}
          order={width > 800 ? 1 : 2}
          style={{ fontSize: width > 800 ? 60 : 40, textAlign: "center" }}
        >
          Prêt à{" "}
          <span style={{ color: theme.colors.theme[6] }}>développer</span> tes
          compétences essentielles ?
        </Title>
      </Center>
      <Center>
        <Title
          mt={10}
          order={width > 800 ? 1 : 2}
          style={{ textAlign: "center" }}
        >
          Rejoins Uply, et transforme ta{" "}
          <span style={{ color: theme.colors.theme[6] }}>progression</span> en
          aventure.
        </Title>
      </Center>

      <Center mt={width > 800 ? 30 : 10}>
        <Image radius="lg" src="/logo_purple.png" h={200} w="auto" alt="logo" />
      </Center>

      <Center m={width > 800 ? 10 : 0}>
        <StartNowButton />
      </Center>

      <Center>
        <Title mt={70} order={width > 800 ? 1 : 2}>
          Les piliers de ton{" "}
          <span style={{ color: theme.colors.theme[6] }}>développement</span>
        </Title>
      </Center>

      <AreasOfLife width={width} />

      <Center>
        <Title mt={30} order={width > 800 ? 1 : 2} mb={30}>
          Les fondations de ta{" "}
          <span style={{ color: theme.colors.theme[6] }}>progression</span>
        </Title>
      </Center>

      <Features width={width} />

      <Center style={{ flexDirection: "column" }}>
        <Title
          mt={70}
          style={{ fontSize: width > 800 ? 75 : 40, textAlign: "center" }}
        >
          Évolue en <span style={{ color: theme.colors.theme[6] }}>jouant</span>
        </Title>
        <Title mt={50} order={width > 800 ? 1 : 2}>
          Deviens la meilleure version de toi-même, un mini-jeu à la fois{" "}
        </Title>
      </Center>

      <Center m={width > 800 ? 10 : 0}>
        <StartNowButton />
      </Center>
    </Box>
  );
};

export default Landing;
