import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../useApi";
import {
  getQuestsForSkill,
  getUserActiveQuests,
  startQuest,
} from "../apiRoutes";
import { notifications } from "@mantine/notifications";
import { Quest } from "../dto/user";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";

export const useQuests = (skillId: string | undefined) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  const {
    data: quests,
    isLoading: questsLoading,
    error: questsError,
  } = useQuery({
    queryKey: ["quests", skillId],
    queryFn: () => getQuestsForSkill(api, +(skillId ? skillId : 0)),
    enabled: !!skillId,
  });

  const { data: activeQuests } = useQuery({
    queryKey: ["activeQuests"],
    queryFn: () => getUserActiveQuests(api),
  });

  const startQuestMutation = useMutation({
    mutationFn: ({
      questId,
      startDate,
      customData,
    }: {
      questId: number;
      startDate: string;
      customData?: {
        customTitle?: string;
        customDescription?: string;
        customPrecision?: string;
      };
    }) => startQuest(api, questId, startDate, customData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["activeQuests"] });
    },
  });

  const getQuestLimit = (userId: number | undefined): number => {
    if (!userId) return 3;
    return [17, 19].includes(userId) ? 10 : 3;
  };

  const handleStartQuest = async (
    questId: number,
    startDateString: string,
    customData?: {
      customTitle?: string;
      customDescription?: string;
      customPrecision?: string;
    }
  ) => {
    const questLimit = getQuestLimit(user?.id);

    if (activeQuests && activeQuests.length >= questLimit) {
      notifications.show({
        title: "Impossible de démarrer la quête",
        message: `Vous avez déjà ${questLimit} quêtes en cours. Terminez-en une avant d'en commencer une nouvelle.`,
        color: "red",
      });
      return false;
    }

    try {
      await startQuestMutation.mutateAsync({
        questId,
        startDate: startDateString,
        customData,
      });

      const questTitle =
        customData?.customTitle ||
        quests?.find((q: Quest) => q.id === questId)?.title ||
        "Nouvelle quête";

      notifications.show({
        title: "Quête démarrée",
        message: `Vous avez démarré la quête "${questTitle}"`,
        color: "green",
      });
      return true;
    } catch (error) {
      notifications.show({
        title: "Erreur",
        message: "Une erreur est survenue lors du démarrage de la quête.",
        color: "red",
      });
      console.error("Erreur lors du démarrage de la quête:", error);
      return false;
    }
  };

  return {
    quests,
    questsLoading,
    questsError,
    activeQuests,
    handleStartQuest,
    questLimit: getQuestLimit(user?.id),
  };
};
