import React from "react";
import {
  Stack,
  Paper,
  UnstyledButton,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

interface Option {
  value: string;
  label: string;
}

interface StyledRadioGroupProps {
  title: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
}

const StyledRadioGroup = ({
  title,
  options,
  value,
  onChange,
}: StyledRadioGroupProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <Text fw={500} mt="xs">
        {title}
      </Text>
      <Stack mt="xs">
        {options.map((option) => (
          <UnstyledButton
            key={option.value}
            onClick={() => onChange(option.value)}
          >
            <Paper
              p="md"
              withBorder
              bg={value === option.value ? "blue.1" : undefined}
              style={{
                borderColor:
                  value === option.value
                    ? "var(--mantine-color-blue-6)"
                    : undefined,
                cursor: "pointer",
                transition: "all 0.2s ease",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  c={
                    value === option.value && colorScheme === "dark"
                      ? "dark"
                      : undefined
                  }
                >
                  {option.label}
                </Text>
                {value === option.value && (
                  <IconCheck size={20} color="var(--mantine-color-blue-6)" />
                )}
              </div>
            </Paper>
          </UnstyledButton>
        ))}
      </Stack>
    </>
  );
};

export default StyledRadioGroup;
