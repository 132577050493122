import React, { useState } from "react";
import {
  Box,
  Button,
  TextInput,
  Textarea,
  Title,
  Code,
  Text,
  LoadingOverlay,
} from "@mantine/core";
import { useApi } from "../useApi";

const QuizGenerator = () => {
  const [topic, setTopic] = useState("");
  const [questions, setQuestions] = useState("");
  const [formattedJson, setFormattedJson] = useState("");
  const [isFormatting, setIsFormatting] = useState(false);
  const api = useApi();

  const handleFormat = async () => {
    try {
      setIsFormatting(true);
      const response = await api.post("/quiz/format", { questions });
      setFormattedJson(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error("Error formatting questions:", error);
    } finally {
      setIsFormatting(false);
    }
  };

  const handleSave = async () => {
    try {
      const questionData = JSON.parse(formattedJson);
      await api.post("/quiz/save", questionData);
      // Handle success (e.g., show notification)
    } catch (error) {
      console.error("Error saving questions:", error);
    }
  };

  return (
    <Box p={16} pos="relative">
      <LoadingOverlay visible={isFormatting} />
      <Title mb="xl">Générateur de Quiz</Title>

      <TextInput
        label="Sujet du quiz"
        placeholder="Entrez le sujet du quiz"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
      />

      <Textarea
        label="Questions"
        placeholder="Entrez les questions du quiz (une par ligne)"
        minRows={20}
        value={questions}
        onChange={(e) => setQuestions(e.target.value)}
        mt="md"
      />

      <Button onClick={handleFormat} disabled={!questions} mt="sm">
        Formatter avec GPT-4
      </Button>

      <Box mt="md">
        <Text mb="sm">JSON Formatté</Text>
        <Code block>{formattedJson}</Code>
      </Box>

      <Button
        onClick={handleSave}
        disabled={!formattedJson}
        mt="sm"
        color="green"
      >
        Sauvegarder
      </Button>
    </Box>
  );
};

export default QuizGenerator;
