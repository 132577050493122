import { Box } from "@mantine/core";
import React from "react";
import AreaSkillsEval from "../Components/eval/AreaSkillsEval";

const Eval = () => {
  return (
    <Box m="md">
      <AreaSkillsEval />
    </Box>
  );
};

export default Eval;
