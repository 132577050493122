import React from "react";
import {
  Text,
  SimpleGrid,
  Paper,
  UnstyledButton,
  Stack,
  Group,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

interface Option {
  value: string;
  label: string;
  icon?: string;
  description?: string;
}

interface MultiOptionSelectorProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  title?: string;
  minSelection?: number;
  maxSelection?: number;
  columns?: number;
}

const MultiOptionSelector = ({
  options,
  value,
  onChange,
  title,
  minSelection = 1,
  maxSelection = Infinity,
  columns = 2,
}: MultiOptionSelectorProps) => {
  const toggleOption = (optionValue: string) => {
    const isSelected = value.includes(optionValue);
    if (isSelected) {
      // Ne pas permettre de désélectionner si on est au minimum et que l'option est déjà sélectionnée
      if (value.length <= minSelection) return;
      onChange(value.filter((v) => v !== optionValue));
    } else {
      // Ne pas permettre de sélectionner si on est au maximum
      if (value.length >= maxSelection) return;
      onChange([...value, optionValue]);
    }
  };

  return (
    <Stack>
      {title && <Text fw={500}>{title} *</Text>}
      <SimpleGrid cols={columns}>
        {options.map((option) => (
          <UnstyledButton
            key={option.value}
            onClick={() => toggleOption(option.value)}
            w="100%"
          >
            <Paper
              p="md"
              withBorder
              bg={value.includes(option.value) ? "blue.1" : undefined}
              style={{
                borderColor: value.includes(option.value)
                  ? "var(--mantine-color-blue-6)"
                  : undefined,
                cursor: "pointer",
                transition: "all 0.2s ease",
              }}
            >
              <Group justify="space-between" align="flex-start">
                <Group>
                  {option.icon && <Text size="xl">{option.icon}</Text>}
                  <Stack gap={0}>
                    <Text fw={500}>{option.label}</Text>
                    {/* {option.description && (
                      <Text size="sm" c="dimmed">
                        {option.description}
                      </Text>
                    )} */}
                  </Stack>
                </Group>
                {value.includes(option.value) && (
                  <IconCheck
                    size={20}
                    style={{ color: "var(--mantine-color-blue-6)" }}
                  />
                )}
              </Group>
            </Paper>
          </UnstyledButton>
        ))}
      </SimpleGrid>
      <Text size="sm" c="dimmed">
        {value.length === 0
          ? `Sélectionnez au moins ${minSelection} option${
              minSelection > 1 ? "s" : ""
            }`
          : `${value.length} option${value.length > 1 ? "s" : ""} sélectionnée${
              value.length > 1 ? "s" : ""
            }`}
        {maxSelection < Infinity && ` (maximum ${maxSelection})`}
      </Text>
    </Stack>
  );
};

export default MultiOptionSelector;
