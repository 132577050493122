import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Title,
  Text,
  Slider,
  Button,
  Group,
  Table,
  Paper,
  Progress,
  Stepper,
  ActionIcon,
} from "@mantine/core";
import { areasOfLife } from "../../utils/areasOfLife";
import { Skill } from "../../dto/user";
import { useSkills } from "../../context/SkillsContext";
import { useApi } from "../../useApi";
import { addSkillEvaluation } from "../../apiRoutes";
import { IconX } from "@tabler/icons-react";

interface RatingTableItem {
  niveau: number;
  description: string;
}

interface SkillEvaluation {
  skillId: number;
  level: number;
  date: Date;
}

const AreaSkillsEval = () => {
  const { areaId, skillId } = useParams<{
    areaId?: string;
    skillId?: string;
  }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const api = useApi();
  const [currentSkillIndex, setCurrentSkillIndex] = useState(0);
  const [skillLevel, setSkillLevel] = useState(0);

  const { skills, isLoading } = useSkills();

  const filteredSkills = useMemo(() => {
    if (!skills) return [];
    if (skillId) {
      return skills.filter((skill: Skill) => skill.id === parseInt(skillId));
    }
    return skills.filter(
      (skill: Skill) => skill.area?.id === parseInt(areaId!)
    );
  }, [skills, skillId, areaId]);

  const area = skillId
    ? areasOfLife.find((a) => a.value === filteredSkills[0]?.area?.value)
    : areasOfLife.find((a) => a.value === areaId);
  const currentSkill = filteredSkills[currentSkillIndex];
  const progress = filteredSkills.length
    ? ((currentSkillIndex + 1) / filteredSkills.length) * 100
    : 0;

  useEffect(() => {
    if (currentSkill?.userLevel !== undefined) {
      setSkillLevel(currentSkill.userLevel);
    } else {
      setSkillLevel(0);
    }
  }, [currentSkill]);

  const addEvaluationMutation = useMutation({
    mutationFn: (evaluation: SkillEvaluation) =>
      addSkillEvaluation(api, evaluation),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["skills"] });
      if (skillId) {
        navigate(-1);
      } else if (currentSkillIndex + 1 < filteredSkills.length) {
        setCurrentSkillIndex((prev) => prev + 1);
        setSkillLevel(0);
      } else if (areaId) {
        navigate(-1);
      } else {
        navigate("/");
      }
    },
  });

  let ratingTable: RatingTableItem[] = [];
  try {
    if (currentSkill?.ratingTableJSON) {
      ratingTable = JSON.parse(currentSkill.ratingTableJSON);
    }
  } catch (e) {
    console.error("Failed to parse ratingTableJSON:", e);
  }

  const handleSave = () => {
    if (!currentSkill) return;

    addEvaluationMutation.mutate({
      skillId: currentSkill.id ?? 0,
      level: skillLevel,
      date: new Date(),
    });
  };

  const handlePrevious = () => {
    if (currentSkillIndex > 0) {
      setCurrentSkillIndex((prev) => prev - 1);
      setSkillLevel(0);
    }
  };

  if (isLoading) return <Text>Loading skills...</Text>;
  if (!filteredSkills?.length) return <Text>No skills found.</Text>;

  return (
    <Box m="md">
      <Paper p="md" mb="md">
        <Group justify="space-between" mb="md">
          <Box>
            <Title order={2}>
              {area?.emoji} {area?.title}
            </Title>
            <Text size="sm" c="dimmed">
              {skillId
                ? "Évaluez cette compétence"
                : "Évaluez vos compétences dans ce domaine"}
            </Text>
          </Box>
          <Group>
            {!skillId && (
              <Text>
                Progression: {currentSkillIndex + 1}/{filteredSkills.length}
              </Text>
            )}
            <ActionIcon onClick={() => navigate(-1)}>
              <IconX />
            </ActionIcon>
          </Group>
        </Group>
        {!skillId && <Progress value={progress} size="sm" mb="xl" />}
      </Paper>

      {!skillId && (
        <Stepper active={currentSkillIndex} orientation="vertical">
          {filteredSkills.map((skill: Skill, index: number) => (
            <Stepper.Step
              key={skill.id}
              label={skill.name}
              description={skill.description}
            />
          ))}
        </Stepper>
      )}

      <Paper p="md" mt="md" withBorder>
        <Title order={3} mb="md">
          {currentSkill?.name}
        </Title>
        <Text mb="xl">{currentSkill?.description}</Text>

        {ratingTable.length > 0 && (
          <Box mb="xl">
            <Title order={4} mb="md">
              Table d'auto-évaluation
            </Title>
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Niveau</Table.Th>
                  <Table.Th>Description</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {ratingTable.map((item: RatingTableItem, index: number) => (
                  <Table.Tr key={index}>
                    <Table.Td>{item.niveau}</Table.Td>
                    <Table.Td>{item.description}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Box>
        )}

        <Box mb="xl">
          <Title order={4} mb="sm">
            Votre niveau : {skillLevel}
          </Title>
          <Slider
            value={skillLevel}
            onChange={setSkillLevel}
            min={0}
            max={10}
            step={0.5}
            marks={[
              { value: 0, label: "0" },
              { value: 2, label: "2" },
              { value: 4, label: "4" },
              { value: 6, label: "6" },
              { value: 8, label: "8" },
              { value: 10, label: "10" },
            ]}
            label={(value) => value.toFixed(1)}
          />
        </Box>

        <Group justify="space-between">
          {!skillId && (
            <Button
              variant="outline"
              onClick={handlePrevious}
              disabled={currentSkillIndex === 0}
            >
              Précédent
            </Button>
          )}
          <Button
            onClick={handleSave}
            loading={addEvaluationMutation.isPending}
          >
            {skillId
              ? "Valider"
              : currentSkillIndex + 1 === filteredSkills.length
              ? "Terminer"
              : "Suivant"}
          </Button>
        </Group>
      </Paper>
    </Box>
  );
};

export default AreaSkillsEval;
