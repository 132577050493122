import React, { useContext, useState } from "react";
import { Stepper, Button, Group, Box, Progress, Stack } from "@mantine/core";
import { areasOfLife } from "../../utils/areasOfLife";
import { useApi } from "../../useApi";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useLocation } from "react-router-dom";
import PseudoStep from "./PseudoStep";
import { UserContext } from "../../context/UserContext";
import MultiOptionSelector from "./MultiOptionSelector";
import StyledRadioGroup from "./StyledRadioGroup";

interface FormData {
  pseudo: string;
  gender: string;
  ageRange: string;
  relationshipStatus: string;
  hasChildren: string;
  areasOfInterest: string[];
  usedSimilarApps: string;
}

const OnboardingForm = () => {
  const [active, setActive] = useState(0);
  const { user, setUser } = useContext(UserContext);
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isGoogleSignup = params.get("google") === "true";
  const [canProgress, setCanProgress] = useState(!isGoogleSignup);

  const [formData, setFormData] = useState<FormData>({
    pseudo: "",
    gender: "",
    ageRange: "",
    relationshipStatus: "",
    hasChildren: "",
    areasOfInterest: [],
    usedSimilarApps: "",
  });

  const totalSteps = isGoogleSignup ? 7 : 6;
  const progress = ((active + 1) / totalSteps) * 100;

  const ageRanges = [
    { value: "18-24", label: "18-24 ans" },
    { value: "25-34", label: "25-34 ans" },
    { value: "35-44", label: "35-44 ans" },
    { value: "45-54", label: "45-54 ans" },
    { value: "55-64", label: "55-64 ans" },
    { value: "65+", label: "65 ans et plus" },
  ];

  const handlePseudoValidated = (pseudo: string) => {
    setFormData((prev) => ({ ...prev, pseudo }));
    setCanProgress(true);
  };

  const handlePseudoError = () => {
    setCanProgress(false);
  };

  const handleSubmit = async () => {
    try {
      if (isGoogleSignup) {
        const response = await api.post("/auth/update-pseudo", {
          pseudo: formData.pseudo,
        });
        if (user) {
          setUser({ ...user, pseudo: response.data.pseudo });
        }
      }

      const { data } = await api.post("/onboarding", {
        userData: {
          gender: formData.gender,
          ageRange: formData.ageRange,
          relationshipStatus: formData.relationshipStatus,
          hasChildren: formData.hasChildren === "true",
          areasOfInterest: formData.areasOfInterest,
        },
        onboardingData: {
          usedSimilarApps: formData.usedSimilarApps === "true",
        },
      });

      setUser(data.user);
      showNotification({
        title: "Succès",
        message: "Vos informations ont été enregistrées avec succès",
        color: "green",
      });
      navigate("/");
    } catch (error) {
      showNotification({
        title: "Erreur",
        message: "Une erreur est survenue lors de l'enregistrement",
        color: "red",
      });
    }
  };

  const canGoNext = () => {
    switch (active) {
      case 0:
        return isGoogleSignup ? canProgress : !!formData.gender;
      case 1:
        return isGoogleSignup ? !!formData.gender : !!formData.ageRange;
      case 2:
        return isGoogleSignup
          ? !!formData.ageRange
          : !!formData.relationshipStatus;
      case 3:
        return isGoogleSignup
          ? !!formData.relationshipStatus
          : !!formData.hasChildren;
      case 4:
        return isGoogleSignup
          ? !!formData.hasChildren
          : formData.areasOfInterest.length > 0;
      case 5:
        return isGoogleSignup
          ? formData.areasOfInterest.length > 0
          : !!formData.usedSimilarApps;
      case 6:
        return !!formData.usedSimilarApps;
      default:
        return false;
    }
  };

  const nextStep = () => {
    if (canGoNext()) {
      setActive((current) =>
        current < totalSteps - 1 ? current + 1 : current
      );
    }
  };

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <Box p="xl">
      <Progress value={progress} mb="md" size="sm" />
      <Stepper active={active}>
        {isGoogleSignup && (
          <Stepper.Step label="Pseudo">
            <PseudoStep
              onPseudoValidated={handlePseudoValidated}
              onError={handlePseudoError}
            />
          </Stepper.Step>
        )}

        <Stepper.Step label="Genre">
          <Stack>
            <StyledRadioGroup
              title="Votre genre *"
              options={[
                { value: "MALE", label: "Homme" },
                { value: "FEMALE", label: "Femme" },
                { value: "OTHER", label: "Autre" },
              ]}
              value={formData.gender}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, gender: value }))
              }
            />
          </Stack>
        </Stepper.Step>

        <Stepper.Step label="Âge">
          <Stack>
            <StyledRadioGroup
              title="Votre tranche d'âge *"
              options={ageRanges}
              value={formData.ageRange}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, ageRange: value }))
              }
            />
          </Stack>
        </Stepper.Step>

        <Stepper.Step label="Relation">
          <Stack>
            <StyledRadioGroup
              title="Êtes-vous en couple ? *"
              options={[
                { value: "SINGLE", label: "Célibataire" },
                { value: "RELATIONSHIP", label: "En couple" },
                { value: "MARRIED", label: "Marié(e)" },
              ]}
              value={formData.relationshipStatus}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, relationshipStatus: value }))
              }
            />
          </Stack>
        </Stepper.Step>

        <Stepper.Step label="Enfants">
          <Stack>
            <StyledRadioGroup
              title="Avez-vous des enfants ? *"
              options={[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]}
              value={formData.hasChildren}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, hasChildren: value }))
              }
            />
          </Stack>
        </Stepper.Step>

        <Stepper.Step label="Intérêts">
          <MultiOptionSelector
            title="Domaines à développer"
            options={areasOfLife.map((area) => ({
              value: area.value,
              label: area.title,
              icon: area.emoji,
              description: area.description,
            }))}
            value={formData.areasOfInterest}
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, areasOfInterest: value }))
            }
            minSelection={1}
            maxSelection={5}
          />
        </Stepper.Step>

        <Stepper.Step label="Expérience">
          <Stack>
            <StyledRadioGroup
              title="Avez-vous déjà utilisé des apps de développement personnel ? *"
              options={[
                { value: "true", label: "Oui" },
                { value: "false", label: "Non" },
              ]}
              value={formData.usedSimilarApps}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, usedSimilarApps: value }))
              }
            />
          </Stack>
        </Stepper.Step>
      </Stepper>

      <Group justify="center" mt="xl">
        {active > 0 && (
          <Button variant="default" onClick={prevStep}>
            Retour
          </Button>
        )}
        {active === totalSteps - 1 ? (
          <Button onClick={handleSubmit} disabled={!canGoNext()}>
            Terminer
          </Button>
        ) : (
          <Button onClick={nextStep} disabled={!canGoNext()}>
            Suivant
          </Button>
        )}
      </Group>
    </Box>
  );
};

export default OnboardingForm;
