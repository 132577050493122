import React, { useState } from "react";
import { Button } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { getQuestById } from "../apiRoutes";
import { useApi } from "../useApi";
import StartQuestModal from "./StartQuestModal";

const CUSTOM_QUEST_ID = 4;

const CustomQuestButton = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);

  const { data: quest } = useQuery({
    queryKey: ["quest", CUSTOM_QUEST_ID],
    queryFn: () => getQuestById(api, CUSTOM_QUEST_ID),
    enabled: true,
  });

  return (
    <>
      <Button onClick={() => setShowModal(true)} variant="light" color="green">
        Créer une quête
      </Button>

      <StartQuestModal
        quest={showModal ? quest : null}
        skillId={undefined}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default CustomQuestButton;
